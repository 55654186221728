var exports = {};
exports = {
  A: {
    A: {
      "2": "J D E F A B rB"
    },
    B: {
      "1": "G M N O",
      "33": "C K L P Q R S V W X Y Z a b c d e f g T h H"
    },
    C: {
      "1": "DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "0 1 2 3 4 5 6 7 8 9 sB gB I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB tB uB"
    },
    D: {
      "33": "0 1 2 3 4 5 6 7 8 9 I i J D E F A B C K L G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB"
    },
    E: {
      "1": "L G 3B 4B nB oB 5B",
      "16": "xB lB",
      "33": "I i J D E F A B C K yB zB 0B 1B mB eB fB 2B"
    },
    F: {
      "2": "F B C 6B 7B 8B 9B eB pB AC fB",
      "33": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S"
    },
    G: {
      "1": "SC TC UC nB oB",
      "16": "lB BC qB CC",
      "33": "E DC EC FC GC HC IC JC KC LC MC NC OC PC QC RC"
    },
    H: {
      "2": "VC"
    },
    I: {
      "16": "gB WC XC YC",
      "33": "I H ZC qB aC bC"
    },
    J: {
      "33": "D A"
    },
    K: {
      "16": "A B C eB pB fB",
      "33": "U"
    },
    L: {
      "33": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "cC"
    },
    P: {
      "33": "I dC eC fC gC hC mB iC jC kC lC mC nC"
    },
    Q: {
      "33": "oC"
    },
    R: {
      "33": "pC"
    },
    S: {
      "1": "qC"
    }
  },
  B: 7,
  C: "Background-clip: text"
};
export default exports;